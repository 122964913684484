<template>
	<v-container
		v-if="$can('create', 'Hosting') || $can('update', 'Hosting')"
		fluid
	>
		<div class="pb-1 px-6 header">
			<h1 class="crud-title">Edit Domain</h1>
		</div>

		<v-radio-group v-model="type" row class="px-6">
			<v-radio
				v-for="type in domainOptions"
				:key="type"
				:label="type"
				:value="type"
			></v-radio>
		</v-radio-group>

		<v-col cols="12" class="d-flex flex-row align-baseline px-6">
			<v-text-field
				:disabled="hosted"
				v-if="type === 'System Domain'"
				v-model="subDomain"
				label="Your Project's Sub-Domain"
				:hint="domainHint"
				:error-messages="errorMessages"
				persistent-hint
				required
			>
			</v-text-field>

			<v-text-field
				:disabled="hosted"
				v-if="type === 'Private Domain'"
				v-model="domain.privateDomain"
				label="Your Project's Domain"
				hint="Eg- www.YourDomain.com"
				persistent-hint
			></v-text-field>

			<p class="ma-0 pa-0 mr-2" v-if="type === 'System Domain'">.</p>
			<v-select
				:disabled="hosted"
				v-if="type === 'System Domain'"
				:items="defaultDomains"
				v-model="domain.systemDomain"
				color="blue"
				label="Project Domain"
				required
			></v-select>
		</v-col>

		<div v-if="nameServer" class="v-row px-6 mb-4 mt-8">
			<v-col cols="12">
				<p>
					<strong> NameServers: </strong>
					<v-tooltip right max-width="300">
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								color="primary"
								dark
								v-bind="attrs"
								v-on="on"
							>
								mdi-help-circle-outline
							</v-icon>
						</template>
						<span>
							Add the following nameservers in your domain
							provider's DNS setting.
						</span>
					</v-tooltip>
				</p>
			</v-col>
			<v-col cols="12">
				<ul class="ms-5" v-for="name in nameServer" :key="name">
					<li>{{ name }}</li>
				</ul>
			</v-col>
		</div>

		<div class="d-flex flex-row px-6 mb-4 mt-8">
			<v-btn
				class="restore-btn mr-4 publish"
				text
				:disabled="generateSslButton"
				v-if="type === 'Private Domain' && hosted"
				@click="generateSsl()"
			>
				Generate Ssl<span class="mdi mdi-arrow-collapse-up pl-2"></span>
			</v-btn>

			<UnoSaveButton
				v-if="!hosted && $can('update', 'Hosting')"
				@click.native="updateDomain()"
				@primary-button-click="
					$router.push({
						name: 'home',
					})
				"
				primary-button-text="Move To Home Page"
				secondary-button-text="Continue Editing"
				:alert="alert"
				:saving="loading"
			></UnoSaveButton>

			<v-tooltip bottom v-if="showPublishButton()">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-if="$can('publish', 'Hosting')"
						class="restore-btn mr-4 publish"
						text
						v-bind="attrs"
						v-on="on"
						@click="showModal = true"
					>
						Publish
						<span class="mdi mdi-arrow-collapse-up pl-2"></span>
					</v-btn>
				</template>
				<span class="tooltip-text">Live this project </span>
			</v-tooltip>

			<v-dialog v-model="showModal" max-width="600px">
				<v-card class="pa-6 mb-0 d-flex flex-column success-modal">
					<div class="d-flex flex-row align-center">
						<span
							class="mdi mdi-checkbox-marked-circle icon mr-2"
						></span>
						<h1 class="heading">
							If Publish you will never be able to change
							sub-domain again. Are you sure to publish with this
							domain?
						</h1>
					</div>
					<div class="d-flex flex-row justify-end">
						<v-btn
							class="mt-4 mr-4"
							v-if="$can('publish', 'Hosting')"
							outlined
							color="#4daf51"
							@click="confirmPublish()"
							:loading="publishing"
						>
							Publish
						</v-btn>
						<v-btn
							class="mt-4"
							outlined
							color="indigo"
							:disabled="disableCancle"
							@click="showModal = false"
						>
							Cancel
						</v-btn>
					</div>
				</v-card>
			</v-dialog>
			<v-dialog v-model="showGenerateSslModal" max-width="600px">
				<v-card class="pa-6 mb-0 d-flex flex-column success-modal">
					<div class="d-flex flex-row align-center">
						<span
							class="mdi mdi-checkbox-marked-circle icon mr-2"
						></span>
						<h1 class="heading">
							Generating SSL,Please wait for a few minutes
						</h1>
					</div>
					<div class="d-flex flex-row justify-end">
						<v-btn
							class="mt-4"
							outlined
							color="indigo"
							@click="showGenerateSslModal = false"
						>
							OK
						</v-btn>
					</div>
				</v-card>
			</v-dialog>

			<v-spacer v-if="type === 'System Domain'"></v-spacer>
			<v-tooltip bottom v-if="type === 'System Domain'">
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						v-bind="attrs"
						v-on="on"
						@click="type = 'Private Domain'"
						text
					>
						Have your private Domain? Click here
					</v-btn>
				</template>
				<span class="tooltip-text">Go to private domain section</span>
			</v-tooltip>
		</div>
	</v-container>
</template>

<script>
import _ from 'lodash'
import UnoSaveButton from '@/components/Buttons/UnoSaveButton'
export default {
	data() {
		return {
			response: {
				domain: null,
				subDomain: null,
				systemDefault: true,
				sslIssuedOn: null,
			},
			nameServer: null,
			subDomain: null,
			domain: {
				systemDomain: null,
				privateDomain: null,
			},
			systemDefault: true,
			hosted: null,
			showGenerateSslModal: false,
			generateSslButton: false,
			defaultDomains: [],
			domainHint: 'Eg-  dr-Ram.uno.com',
			type: 'System Domain',
			domainOptions: ['System Domain', 'Private Domain'],
			alert: {
				show: null,
				message: null,
				error: false,
			},
			errorMessages: null,
			loading: false,
			publishing: false,
			disableCancle: false,
			dialog: false,
			showModal: false,
		}
	},
	components: {
		UnoSaveButton,
	},
	created() {
		this.getDefaultDomains()
		this.getDomainData()
		this.getNameServer()

		// debounce registering
		this.debouncedDomainAvailability = _.debounce(
			this.checkDomainAvailability,
			500
		)
	},
	watch: {
		privateDomain() {
			this.debouncedDomainAvailability()
		},
		systemDomain() {
			if (this.subDomain !== null && this.domain.systemDomain !== null) {
				this.domainHint =
					'Your Domain will be - ' +
					this.subDomain +
					'.' +
					this.domain.systemDomain
				this.debouncedDomainAvailability()
			}
			if (this.subDomain !== null && this.domain.systemDomain === null) {
				this.domainHint = 'Please select Domain'
			}
			if (
				(this.subDomain === null || this.subDomain === '') &&
				this.domain.systemDomain !== null
			) {
				this.domainHint = 'Please enter sub-domain'
			}
		},
		subDomain() {
			if (this.subDomain !== null && this.domain.systemDomain !== null) {
				this.domainHint =
					'Your Domain will be - ' +
					this.subDomain +
					'.' +
					this.domain.systemDomain
				this.debouncedDomainAvailability()
			}
			if (this.subDomain !== null && this.domain.systemDomain === null) {
				this.domainHint = 'Please select Domain'
			}
			if (
				(this.subDomain === null || this.subDomain === '') &&
				this.domain.systemDomain !== null
			) {
				this.domainHint = 'Please enter sub-domain'
			}
		},
	},
	computed: {
		privateDomain() {
			return this.domain.privateDomain
		},
		systemDomain() {
			return this.domain.systemDomain
		},
	},
	methods: {
		generateSsl() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'post',
				url: `/${projectId}/hosting/ssl/generate`,
			}).then((res) => {
				if (res.data.success) {
					this.showGenerateSslModal = true
					this.generateSslButton = true
				}
			})
		},

		getDomainData() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/${projectId}/hosting`,
			}).then((res) => {
				let data = res.data.data
				this.setDomainData(data)
				this.hosted = data.hosted
			})
		},

		getNameServer() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/${projectId}/hosting/nameservers`,
			}).then((res) => {
				let data = res.data.nameServers
				this.setNameServer(data)
			})
		},

		getDefaultDomains() {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/${projectId}/hosting/defaultdomains`,
			}).then((res) => {
				let data = res.data.data
				this.defaultDomains = data
			})
		},
		setNameServer(data) {
			this.nameServer = data
		},
		setDomainData(data) {
			if (data.systemDefault) {
				this.domain.systemDomain = data.domain
				this.domain.privateDomain = null
				this.type = 'System Domain'
			} else {
				this.domain.privateDomain = data.domain
				this.domain.systemDomain = null
				this.type = 'Private Domain'
			}
			this.subDomain = data.subDomain
			this.systemDefault = data.systemDefault

			this.response.domain = data.domain
			this.response.subDomain = data.subDomain
			this.response.systemDefault = data.systemDefault
			this.response.sslIssuedOn = data.sslIssuedOn
			if (data.sslIssuedOn) {
				this.generateSslButton = true
			}
		},
		checkDomainAvailability() {
			const projectId = this.$store.state.app.projectId

			if (this.type === 'System Domain') {
				this.axios({
					method: 'get',
					url: `/${projectId}/hosting/checkavailable`,
					params: {
						domain: this.domain.systemDomain,
						subDomain: this.subDomain,
					},
				})
					.then((res) => {
						if (res.status == 200 && res.data.success) {
							return (this.errorMessages = null)
						} else {
							return (this.errorMessages =
								'This Domain not available')
						}
					})
					.catch((error) => {
						const { data } = error.response
						if (data.statusCode == 400) {
							this.$snackbar.notify({
								message: data.message,
								color: 'black',
							})
						}
					})
			} else {
				this.axios({
					method: 'get',
					url: `/${projectId}/hosting/checkavailable`,
					params: {
						domain: this.domain.privateDomain,
						subDomain: null,
					},
				})
					.then((res) => {
						if (res.status == 200 && res.data.success) {
							return (this.errorMessages = null)
						} else {
							return (this.errorMessages =
								'This Domain not available')
						}
					})
					.catch((error) => {
						const { data } = error.response
						if (data.statusCode == 400) {
							this.$snackbar.notify({
								message: data.message,
								color: 'black',
							})
						}
					})
			}
		},
		showPublishButton() {
			if (!this.hosted) {
				if (
					this.type === 'System Domain' &&
					this.response.domain === this.domain.systemDomain &&
					this.response.subDomain === this.subDomain &&
					this.domain.privateDomain === null
				)
					return true
				if (
					this.type === 'Private Domain' &&
					this.response.domain === this.domain.privateDomain &&
					this.subDomain === null &&
					this.domain.systemDomain === null
				)
					return true
			}
		},
		updateDomain() {
			this.loading = true
			const projectId = this.$store.state.app.projectId
			if (this.type === 'System Domain') {
				let payload = {
					domain: this.domain.systemDomain,
					subDomain: this.subDomain,
					systemDefault: true,
				}
				this.axios({
					method: 'post',
					url: `/${projectId}/hosting`,
					data: payload,
				})
					.then((res) => {
						if (res.status == 200 && res.data.success) {
							const data = res.data.data
							this.setDomainData(data)
							this.alert.message =
								res.data.message +
								'. You could publish your domain but once published you will never be able to change it again'
							this.loading = false
						} else {
							console.log(res.data.message)
							this.loading = false
						}
					})
					.catch((error) => {
						const { data } = error.response
						if (data.statusCode == 400) {
							this.loading = false
							this.$snackbar.notify({
								message: data.message,
								color: 'black',
							})
						}
					})
			} else {
				let payload = {
					domain: this.domain.privateDomain,
					subDomain: null,
					systemDefault: false,
				}
				this.axios({
					method: 'post',
					url: `/${projectId}/hosting`,
					data: payload,
				})
					.then((res) => {
						if (res.status == 200 && res.data.success) {
							const data = res.data.data
							this.setDomainData(data)
							this.alert.message =
								res.data.message +
								'. You could publish your domain but once published you will never be able to change it again'
							this.loading = false
						} else {
							//
						}
					})
					.catch((error) => {
						const { data } = error.response
						if (data.statusCode == 400) {
							this.loading = false
							this.$snackbar.notify({
								message: data.message,
								color: 'black',
							})
						}
					})
			}
		},
		confirmPublish() {
			this.publishing = true
			this.disableCancle = true
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'put',
				url: `/${projectId}/hosting/publish`,
			}).then(() => {
				this.getDomainData()
				this.showModal = false
				this.publishing = false
				this.disableCancle = false
			})
		},
	},
}
</script>

<style scoped>
.header {
	border-bottom: 1px solid #eee;
}
.restore-btn {
	padding: 0 16px !important;
}
.publish {
	color: #4caf50;
}
.publish:hover {
	background-color: #e8f5e9;
}
.unpublish {
	color: #e65100 !important;
	border-radius: 7px !important;
	padding: 17px !important;
}
.unpublish:hover {
	background-color: #ffe0b2;
}
.success-modal .heading {
	color: #4daf51;
	font-weight: 400;
	font-size: 1.5rem;
}
.success-modal .icon {
	font-size: 1.7rem;
	color: #4daf51;
}
li {
	list-style: disc !important;
}
</style>
